<template>
  <div class="about">
    <h1>Users</h1>
    <b-container>
      <!-- <ve-table :columns="columns" :table-data="profiles" /> -->
      <b-tabs content-class="mt-3">
        <b-tab title="PROD" @click="selectType = 'prod'"> </b-tab>
        <b-tab title="STAG" @click="selectType = 'stag'"></b-tab>
        <b-tab title="DEV" active @click="selectType = 'dev'"></b-tab>
      </b-tabs>
      <b-table
        hover
        :items="profiles.filter((el) => el.type === selectType)"
        :busy="isBusy"
        :fields="fields"
        primary-key="email"
        outlined
        bordered
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner
              type="grow"
              variant="primary"
              class="align-middle mr-1"
            ></b-spinner>
            <strong class="text-primary">Loading...</strong>
          </div>
        </template>
        <template #cell(status)="data">
          <div v-if="data.item.status === 'Pending'">
            <b-button class="" variant="warning">{{
              data.item.status
            }}</b-button>
          </div>
          <div v-else>
            <b-button class="" variant="success">{{
              data.item.status
            }}</b-button>
          </div>
        </template>
        <template #cell(action)="data">
          <b-button @click="openModal(data.item)" variant="primary">
            Details
          </b-button>
        </template>
      </b-table>
    </b-container>
    <b-modal size="lg" id="modal-1">
      <!-- <p class="my-4">Hello from modal!</p> -->
      <template #modal-title>
        <!-- <div v-if="modalLoading"> -->
        <div class="ml-4">
          {{ modal_details.userDetails.name || "" }} -
          {{ modal_details.userDetails.email || "" }} -
          <span class="text-primary">{{
            modal_details.userDetails.user_id || ""
          }}</span>
        </div>
        <!-- </div> -->
      </template>
      <div class="px-4">
        <div v-if="modalLoading">
          <h4>Details</h4>
          <div>
            User has
            <span class="text-primary font-weight-bolder">{{
              modal_details.transactionCount
            }}</span>
            transactions
          </div>
          <div>
            User has
            <span class="text-primary font-weight-bolder">{{
              modal_details.logsCount
            }}</span>
            logs
          </div>
          <div>
            User has
            <span class="text-primary font-weight-bolder">{{
              modal_details.algoliaCount
            }}</span>
            algolia records
          </div>
          <!-- <br /> -->
          <hr />
          <br />
          <h4>Accounts</h4>

          <div v-if="modal_details.accounts.length > 0">
            <div
              v-for="(account, index) in modal_details.accounts"
              :key="index"
            >
              <b-card class="shadow">
                <div class="d-flex justify-content-between">
                  <h5>
                    {{ account.institution_name }}
                  </h5>
                  <div v-if="account.status === 'Active'">
                    <b-button
                      variant="success"
                      @click="getAccountLogs(account)"
                    >
                      {{ account.status }}
                    </b-button>
                  </div>
                  <div v-if="account.status === 'Inactive'">
                    <b-button
                      variant="secondary"
                      @click="getAccountLogs(account)"
                    >
                      {{ account.status }}
                    </b-button>
                  </div>
                  <div v-if="account.status === 'Error'">
                    <b-button variant="danger" @click="getAccountLogs(account)">
                      {{ account.status }}
                    </b-button>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="text-primary">
                    {{ account.name }}
                  </div>
                </div>
                <br />
                <div>
                  Added on -
                  <span class="font-weight-bold">{{ account.created }}</span>
                </div>
                <div>
                  Last activity -
                  <span class="font-weight-bold">{{
                    account.last_transaction_update_date
                  }}</span>
                </div>
                <div>
                  Access token -
                  <span class="font-weight-bold">{{
                    account.access_token
                  }}</span>
                </div>
                <div>
                  Acccess token valid -
                  <span
                    :class="{
                      'text-success': account.access_token_valid,
                      'text-danger': !account.access_token_valid,
                    }"
                    class="font-weight-bold"
                    >{{ account.access_token_valid }}</span
                  >
                </div>
                <br />
                <div class="d-flex justify-content-around">
                  <l-button
                    v-if="modal_details.algoliaCount > 0"
                    @click.native="
                      clearAlgolia(
                        modal_details.userDetails,
                        account.account_id
                      )
                    "
                    :loaderPass="lbuttonProp.clearAlgolia"
                    variant="secondary"
                    :loadingText="'Clearing'"
                    :doneText="'Cleared'"
                    >Clear Algolia</l-button
                  >
                  <l-button
                    v-if="account.access_token_valid === true"
                    @click.native="
                      disconnectPlaid(
                        modal_details.userDetails,
                        account.account_id
                      )
                    "
                    :loaderPass="lbuttonProp.disconnectPlaid"
                    variant="warning"
                    >Disconnect Plaid</l-button
                  >
                  <l-button
                    :loaderPass="lbuttonProp.clearDB"
                    @click.native="
                      clearDB(modal_details.userDetails, account.account_id)
                    "
                    variant="danger"
                    >Clear DB</l-button
                  >
                </div>
              </b-card>
              <br />
            </div>
          </div>
          <div v-else>No accounts added</div>
        </div>
        <div class="text-center d-flex justify-content-center" v-else>
          <b-spinner variant="primary" type="grow"></b-spinner>
          <div class="ml-1 text-primary align-self-center">Loading...</div>
        </div>
      </div>
    </b-modal>
    <b-modal title="Account Logs" size="xl" id="logs-modal">
      <b-container>
        <b-table
          responsive
          fixed
          bordered
          :busy="accountLogsLoading"
          :items="accountLogs"
          :fields="logFields"
        >
          <template #table-busy>
            <div
              class="text-center text-primary my-2 d-flex justify-content-center"
            >
              <b-spinner
                variant="primary"
                type="grow"
                class="align-middle mr-1"
              ></b-spinner>
              <div class="text-primary align-self-center">Loading...</div>
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'detail' ? '200px' : '100px' }"
            />
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
// import LButton from "../components/LButton";
import LButton from "../components/LButton.vue";
export default {
  components: {
    LButton,
  },
  mounted() {
    // this.$realm
    //   .transactionsCollection("dev")
    //   .deleteMany({ user_id: "5f87643c415d8753ff5b6186" });
  },
  async created() {
    await this.$realm.login("salman@fostrum.com", "Rose19$$#CTO");
    this.getUserProfiles();
    // console.log("Current user", user);
    // let result = await
    // this.$realm.usersCollection.find().then((res) => {
    //   this.profiles = res.filter((el) => el.type === "dev");
    //   this.toggleBusy();
    //   console.log(this.profiles);
    // });
    // this.profiles = result.filter((el) => el.type === "dev");
    // this.toggleBusy();
    // console.log(this.profiles);
    // this.profiles = result;
  },
  data() {
    return {
      selectType: "dev",
      isBusy: true,
      modalLoading: false,
      profiles: [],
      logFields: [
        {
          key: "date",
          sortable: true,
          label: "Date",
          class: "text-left",
        },
        {
          key: "type",
          sortable: true,
          label: "Type",
          class: "text-left",
        },
        {
          key: "tags",
          sortable: true,
          label: "Tags",
          class: "text-left",
        },
        {
          key: "detail",
          label: "Details",
          sortable: false,
          // Variant applies to the whole column, including the header and footer
          // variant: "warning",
          class: "text-left",
        },
      ],
      fields: [
        {
          key: "user_id",
          sortable: false,
          label: "User ID",
          class: "text-left",
        },
        {
          key: "email",
          sortable: true,
          label: "Email",
          class: "text-left",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: "warning",
          class: "text-left",
        },
        {
          key: "name",
          sortable: false,
          label: "Name",
          class: "text-left",
        },
        { key: "action", label: "Action" },
      ],
      modal_details: "",
      accountLogs: [],
      accountLogsLoading: false,
      lbuttonProp: {
        clearAlgolia: "",
        disconnectPlaid: "",
        clearDB: "",
      },
    };
  },
  methods: {
    async disconnectPlaid(user, account_id) {
      this.lbuttonProp.disconnectPlaid = "pending";
      var url = "";
      if (user.type == "dev") {
        url = "https://dev-bank.fostrum.com/remove";
      }
      if (user.type == "stag") {
        url = "https://stag-bank.fostrum.com/remove";
      }
      if (user.type == "prod") {
        url = "https://bank.fostrum.com/remove";
      }
      axios({
        url: url,
        method: "post",
        data: {
          USER_ID: user.user_id,
          ACCOUNT_ID: account_id,
        },
      })
        .then((res) => {
          console.log(res);
          this.lbuttonProp.disconnectPlaid = "done";
          setTimeout(() => {
            this.lbuttonProp.disconnectPlaid = "";
            this.getDetails(user);
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
          this.lbuttonProp.disconnectPlaid = "error";
          setTimeout(() => {
            this.lbuttonProp.disconnectPlaid = "";
            this.getDetails(user);
          }, 2000);
        });

      // return result.data;
      // console.log(result.data);
    },
    clearAlgolia(user, account_id) {
      this.lbuttonProp.clearAlgolia = "pending";
      var url = "";
      if (user.type == "dev") {
        url = "https://dev-bank.fostrum.com/algolia/clear";
      }
      if (user.type == "stag") {
        url = "https://stag-bank.fostrum.com/algolia/clear";
      }
      if (user.type == "prod") {
        url = "https://bank.fostrum.com/algolia/clear";
      }
      console.log("Clear algolia user_id", user.email, user.user_id);
      return axios({
        url: url,
        method: "post",
        data: {
          USER_ID: user.user_id,
          ACCOUNT_ID: account_id,
        },
      })
        .then((res) => {
          this.lbuttonProp.clearAlgolia = "done";
          setTimeout(() => {
            this.lbuttonProp.clearAlgolia = "";
            this.getDetails(user);
          }, 3000);
          console.log(res);
          // this.getDetails(user);
        })
        .catch((err) => {
          console.log(err);

          this.lbuttonProp.clearAlgolia = "error";
          setTimeout(() => {
            this.lbuttonProp.clearAlgolia = "";
            this.getDetails(user);
          }, 3000);
          // this.getDetails(user);
        });
    },
    getUserProfiles() {
      this.$realm.usersCollection.find().then((res) => {
        this.profiles = res; //.filter((el) => el.type === selectTypeType);
        this.toggleBusy();
        // console.log("Profiles", this.profiles);
      });
    },
    openModal(arg) {
      this.$bvModal.show("modal-1");
      this.getDetails(arg);
      this.modalLoading = false;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    async getAlgoliaRecordCount(user) {
      var url = "https://dev-bank.fostrum.com/algolia/count";
      if (user.type == "dev") {
        url = "https://dev-bank.fostrum.com/algolia/count";
      }
      if (user.type == "stag") {
        url = "https://stag-bank.fostrum.com/algolia/count";
      }
      if (user.type == "prod") {
        url = "https://bank.fostrum.com/algolia/count";
      }
      let r = await axios({
        url: url,
        method: "post",
        data: {
          USER_ID: user.user_id,
        },
      });
      return r.data.result;
    },
    async getAccountLogs(arg) {
      this.accountLogsLoading = true;
      this.$bvModal.show("logs-modal");
      const query = { user_id: arg.user_id, account_id: arg.account_id };
      const logsCount = await this.$realm
        .logsCollection(this.modal_details.userDetails.type)
        .find(query, { sort: { date: -1 } });
      // .sort({ date: -1 });
      this.accountLogsLoading = false;

      console.log(logsCount);
      this.accountLogs = logsCount;
    },

    async getDetails(arg) {
      this.modalLoading = false;
      this.modal_details = {
        userDetails: arg,
      };
      const query = { user_id: arg.user_id };
      const txnCount = this.$realm
        .transactionsCollection(arg.type)
        .count(query);
      const account = this.$realm.accountsCollection(arg.type).find(query);
      const balancesCount = this.$realm
        .balancesCollection(arg.type)
        .count(query);
      const logsCount = this.$realm.logsCollection(arg.type).count(query);
      const algoliaCount = this.getAlgoliaRecordCount(arg);
      let pro = await Promise.all([
        txnCount,
        account,
        balancesCount,
        logsCount,
        algoliaCount,
      ]);
      this.modal_details = {
        userDetails: arg,
        transactionCount: pro[0],
        accounts: pro[1],
        balancesCount: pro[2],
        logsCount: pro[3],
        algoliaCount: pro[4],
      };
      this.modalLoading = true;

      console.log("Pro", pro);
    },
    async clearDB(arg, account_id) {
      this.lbuttonProp.clearDB = "pending";
      // this.modalLoader = false;
      var url = "";
      if (arg.type == "dev") {
        url = "https://dev-bank.fostrum.com/delete";
      }
      if (arg.type == "stag") {
        url = "https://stag-bank.fostrum.com/delete";
      }
      if (arg.type == "prod") {
        url = "https://bank.fostrum.com/delete";
      }
      const query = {
        user_id: arg.user_id,
      };
      // var promiseCalls = [];
      try {
        await axios({
          url: url,
          method: "post",
          data: {
            USER_ID: arg.user_id,
            ACCOUNT_ID: account_id,
          },
        })
          .then((res) => console.log("Deleted Transactions", res))
          .catch((err) => console.log("Error txn collections", err));

        await this.$realm
          .logsCollection(arg.type)
          .deleteMany(query)
          .then((res) => console.log("Deleted Logs", res))
          .catch((err) => console.log("Error Logs collections", err));
        await this.$realm
          .accountsCollection(arg.type)
          .deleteMany(query)
          .then((res) => console.log("Deleted Accounts", res))
          .catch((err) => console.log("Error Accounts collections", err));
        // );
        await this.$realm
          .balancesCollection(arg.type)
          .deleteMany(query)
          .then((res) => console.log("Deleted Balances", res))
          .catch((err) => console.log("Error Balances collections", err));
        await this.$realm
          .goalsCollection(arg.type)
          .deleteMany({ user_id: arg.user_id })
          .then((res) => console.log("Deleted Goals", res))
          .catch((err) => console.log("Error Goals collections", err));
        await this.$realm
          .rulesCollection(arg.type)
          .deleteMany({ user_id: arg.user_id })
          .then((res) => console.log("Deleted Rules", res))
          .catch((err) => console.log("Error Rules collections", err));
        this.lbuttonProp.clearDB = "done";
        setTimeout(() => {
          this.lbuttonProp.clearDB = "";
        }, 3000);
      } catch (e) {
        console.log(e);
        this.lbuttonProp.clearDB = "error";
        setTimeout(() => {
          this.lbuttonProp.clearDB = "";
        }, 3000);
      }
      await this.getDetails(arg);
      // this.modalLoader = true;
    },
  },
};
</script>