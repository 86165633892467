import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Realm from "../realm.js"
Vue.prototype.$realm = Realm
Vue.config.productionTip = false
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
// import {
//   ServerTable,
//   ClientTable,
//   Event
// } from 'vue-tables-2';

// Vue.use(ClientTable);
// Vue.use(ServerTable);
import "vue-easytable/libs/theme-default/index.css"; // import style
// import VueEasytable from "vue-easytable"; // import library
import {
  VeTable
} from "vue-easytable";

Vue.use(VeTable);


new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')