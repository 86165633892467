<template>
  <div class="text-center">
    <div v-if="loaderPass === 'pending'">
      <b-button class="trust-button" :variant="variant" :disabled="true">
        <span class="mr-1">{{ loadingText || "Loading" }}</span>
        <b-spinner :variant="'white'" small label="Loading"></b-spinner>
      </b-button>
    </div>
    <div v-else-if="loaderPass === 'done'">
      <b-button class="trust-button" :variant="'success'">
        {{ doneText || "Done" }}
      </b-button>
    </div>
    <div v-else-if="loaderPass === 'error'">
      <b-button class="trust-button" :variant="'danger'"> Try again </b-button>
    </div>
    <div v-else>
      <b-button class="trust-button" :variant="variant">
        <slot></slot>
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton, BSpinner } from "bootstrap-vue";
export default {
  props: ["variant", "loaderPass", "loadingText", "doneText"],
  components: {
    BButton,
    BSpinner,
  },
  data() {
    return {
      //   loaderPass: "",
    };
  },
  computed: {
    // loaderPass() {
    //   return this.loaderStatus;
    // }
  },
  methods: {
    // async passedFunction() {
    //   this.loaderPass = "pending";
    //   this.$emit("loadFunction");
    //   const ref = this;
    //   try {
    //     this.passedFunc().then((res) => {
    //       console.log("Result", res);
    //       this.loaderPass = "done";
    //     });
    //   } catch (e) {
    //     console.log("Error", e);
    //     this.loaderPass = "error";
    //     setTimeout(() => {
    //       ref.loaderPass = "done";
    //     }, 3000);
    //   }
    // },
  },
};
</script>
