import * as Realm from "realm-web";
const id = "zen-admin-gcgdw"
const appConfig = {
    id: id,
    timeout: 10000
};
const app = new Realm.App(appConfig)
// console.log(app)

// const db = process.env.VUE_APP_STAGE_DB

async function loginEmailPassword(email, password) {
    // Create an anonymous credential
    const credentials = Realm.Credentials.emailPassword(email, password);
    try {
        // Authenticate the user
        const user = await app.logIn(credentials);
        // `App.currentUser` updates to match the logged in user
        // assert(user.id === app.currentUser.id)
        return user
    } catch (err) {
        console.error("Failed to log in", err);
    }
}

// async function run() {
//     let user;
//     try {
//         // var app = Realm.App.getApp(id) //new Realm.App(appConfig);
//         // an authenticated user is required to access a MongoDB instance
//         // user = await app.logIn(Realm.Credentials.anonymous());
//         user = await loginEmailPassword("salman@fostrum.com", "Rose19$$#CTO")
//         console.log("User", user)
//         const mongo = user.mongoClient("zen-admin");
//         const mongoCollection = mongo.db("dev").collection("profiles");
//         console.log(mongoCollection)
//         // the rest of your code ...
//     } finally {
//         user.logOut();
//     }
// }
loginEmailPassword("salman@fostrum.com", "Rose19$$#CTO");
// run().catch(console.dir);

// console.log(app.currentUser.mongoClient("zen-admin"))

// const mongo = app.currentUser.mongoClient("mongodb-atlas");
// console.log("Here", app.currentUser)

function mongo() {
    return app.currentUser.mongoClient("mongodb-atlas");
}
const usersCollection = mongo().db("users").collection("profiles");
// const transactionsCollection = mongo.db(db).collection("transactions");
function transactionsCollection(db) {
    return mongo().db(db).collection("transactions");
}

function balancesCollection(db) {
    return mongo().db(db).collection("balances");
}
// const balancesCollection = mongo().db(db).collection("balances");
function logsCollection(db) {
    return mongo().db(db).collection("logs");
}
// const logsCollection = mongo().db(db).collection("logs");
function accountsCollection(db) {
    return mongo().db(db).collection("accounts");

}

function goalsCollection(db) {
    return mongo().db(db).collection("goals");

}

function rulesCollection(db) {
    return mongo().db(db).collection("rules");

}
// const accountsCollection = mongo().db(db).collection("accounts");


// loginEmailPassword("joe.jasper@example.com", "passw0rd").then(user => {
//     console.log("Successfully logged in!", user)
// })


export default {
    login: loginEmailPassword,
    app: app,
    usersCollection,
    transactionsCollection,
    accountsCollection,
    logsCollection,
    balancesCollection,
    goalsCollection,
    rulesCollection
}